if (module.hot) {
  module.hot.accept()
}

//  wrap fetch with XMLHttpRequest if fetch not available
if (!window.fetch) {
  window.fetch = function (url, options) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest()
      xhr.open(options ? options.method || "GET" : "GET", url)
      xhr.onload = function () {
        resolve(new Response(xhr.responseText, { status: xhr.status }))
      }
      xhr.onerror = function () {
        reject(new TypeError("Network request failed"))
      }
      xhr.send(options ? options.body : undefined)
    })
  }
}

window.addRow = function (label, value) {
  var tr, td
  tr = document.createElement("tr")
  td = document.createElement("td")
  td.innerHTML = label
  td.className = "first-col"
  tr.appendChild(td)
  td = document.createElement("td")
  td.innerHTML = value
  td.style.maxWidth = "80%"
  td.style.wordBreak = "break-all"

  tr.appendChild(td)

  return tr
}

function isWebpackDevServer() {
  return window.location.hostname === "localhost"
}

if (isWebpackDevServer()) {
  console.log("Running on webpack devServer")
} else {
  var link = document.createElement("link")
  link.rel = "stylesheet"
  link.href = "/main.css"
  document.head.appendChild(link)
}

document.addEventListener("DOMContentLoaded", function () {
  var verifyCodeImg = document.querySelector("#verify-code")
  if (verifyCodeImg) {
    var reloadVerifyCode = function () {
      fetch("/api/session/code")
        .then((res) => res.json())
        .then((data) => {
          if (data.code !== 0) {
            console.error(data)
            return
          }
          if (!data.data) {
            console.error("no data")
            return
          }
          var sessionId = data.data
          document.querySelector("#sessionId").value = sessionId
          verifyCodeImg.src = "/api/session/captcha?sessionId=" + sessionId
        })
        .catch((err) => {
          console.error(err)
        })
    }
    verifyCodeImg.addEventListener("click", function () {
      reloadVerifyCode()
    })
    reloadVerifyCode()
  }

  var photoUploader = document.getElementById("photoUploader")
  if (photoUploader) {
    var photoBase64 = document.getElementById("photoBase64")
    var photoPreview = document.getElementById("photoPreview")
    var photoFilename = document.getElementById("photoFilename")

    photoPreview.addEventListener("click", function () {
      photoUploader.click()
    })

    photoUploader.addEventListener("change", function () {
      var file = photoUploader.files[0]
      if (file && file.size <= 6 * 1024 * 1024) {
        // 限制文件大小为 4MB
        var reader = new FileReader()
        reader.onload = function (e) {
          photoBase64.value = e.target.result.split(",")[1] // 将 Base64 字符串存储在隐藏字段中
          photoPreview.src = e.target.result // 更新预览图片
          photoFilename.value = file.name
        }
        reader.readAsDataURL(file)
      } else {
        alert("文件大小不能超过 4MB")
        photoUploader.value = "" // 清空文件输入
      }
    })
  }
})
